.feedback-form-uploads-wrap {
  position: relative;
}

.feedback-form-uploads-loading {
  background-color: rgba(255, 255, 255, 0.82);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 33;
}

.feedback-form-uploads-loading > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.feedback-form-uploads-loading .Polaris-Spinner  {
  display: block;
  margin: 0 auto;
}